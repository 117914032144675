export default function() {

  let inputs = document.querySelectorAll('.js--text__count');

  // datepickerを動的に増やす
  $('body').on('click','.js--add__textCount',function(){

    let allInputs = document.querySelectorAll('.js--text__count');
    var cntNum = allInputs.length + 1;
    var addNum = ("00" + cntNum).slice( -2 ) ;

    var textarea = '<div class="u--flexBlock u--flexBlock__itemsCenter u--fs__12"><textarea name="" cols="130" rows="4" class="u--w__per--90 u--mgt--15 js--text__count" placeholder="" data-print-id="text__print--' + addNum +'" data-maxlength="500"></textarea><p class="c--text__print u--mgl--10"><span id="text__print--' + addNum +'">0</span>文字</p></div>';

    $(this).parent().append(textarea);

    let inputs = document.querySelectorAll('.js--text__count');
    for (var i = 0, len = inputs.length; i < len; ++i) {
      inputs[i].dataset.num = i;
      stextCount(inputs[i]);
      inputs[i].addEventListener('input', function() {
        stextCount(this);
      });
    }

  })



  /** ------------------------------
   * テキストエリアの文字数カウント実行
   * ---------------------------- */
  for (var i = 0, len = inputs.length; i < len; ++i) {
    inputs[i].dataset.num = i;

    // let txt = inputs[i].value;
    // let pid = inputs[i].getAttribute('data-print-id');
    // let maxlen = inputs[i].getAttribute('data-maxlength');
    // stextCount(txt, pid, maxlen, inputs[i]);
    stextCount(inputs[i]);

    inputs[i].addEventListener('input', function() {
      // let txt = this.value;
      // let pid = this.getAttribute('data-print-id');
      // let maxlen = this.getAttribute('data-maxlength');
      // stextCount(txt, pid, maxlen, this);
      stextCount(this);
    });
  }

  /** ------------------------------
   * textCount
   * 文字数カウント（1Byteも2byteも1文字）
   * @param text - カウントするテキスト文字列
   * @param printId - 文字数を表示する要素のID
   * ---------------------------- */
  // function stextCount(text, printId, maxlen, ore) {
  function stextCount(elem) {
    //  let str = text.replace(/\n/g, "");//改行をとる
    //let str = text.replace(/\n/g, "\n\n");//改行を２文字として計算

    let str = elem.value.replace(/\n/g, "\n\n");//改行を２文字として計算
    let cntview = document.getElementById(elem.getAttribute('data-print-id'));
    let maxlen = elem.getAttribute('data-maxlength');
    let strlen = Array.from(str).length;


    // 文字カウントオーバーの処理
    if(maxlen) {
      if(strlen > maxlen) {
        cntview.parentNode.classList.add("is--over");
        elem.classList.add("is--over");
      } else if(strlen <= maxlen && elem.classList.contains("is--over")) {
        elem.classList.remove("is--over");
        cntview.parentNode.classList.remove("is--over");
      }
    }

    //カウント表示
    cntview.textContent = strlen;

  }


}
