/*! 
 * (C)Mynavi Corporation
 * 画面毎のUI
 */

import MicroModal from 'micromodal'; //モーダル
import textCount from '../../../common/js/text-count'; //文字数カウント
import autosize from 'autosize'; //テキストエリアを自動で調整

let ps; // PerfectScrollbar
let isPsb = false; // PerfectScrollbarが実行されてるか

/** ============================================================
 * 
 * ========================================================== */
/** ------------------------------
 * 
 * ---------------------------- */
window.addEventListener('load', function(){
  modal(); //モーダル
  firstPopUp(); //お知らせポップアップ
  selectCenter(); //セレクトの文字を中央寄せ
  textCount(); //テキストカウント

  if (window.mnstate.isMobile == true) {
    // SP
    toggleSwitchSP();
  }

  fixedBnr(); //下にバナー表示
  // fixedBnrClickBtn();
  accordionTable();
  cellToggle(300);//比較ページの開くセル。
  tabChange();
  inputErrorEdit();

  stretchTextarea();
  pageLink(); //ページ内リンク

  sortArrowTgl();
  selectErrorTxtColor();

  favChangeAnime(window.mnstate.isMobile);

  inputPreview();
  rorateImg();
  tempSaveBtn();

  numberBranchSlider();
  // numberBranchSliderInModal();

  radioAllClear();

  textStrimwidth();

});


/** ------------------------------
 * クリックで子メニューを表示(spのみ)
 * ---------------------------- */
function toggleSwitchSP() {
  $('.js--tglSwitch--sp').each(function() {
    var toggle_group = $(this).attr('data-toggle-parent');
    if($(this).hasClass('c--tglSwitch--open')){
      $('.js--tglSwitch__child--sp[data-toggle-child=' + toggle_group + ']').show();
    }else{
      $('.js--tglSwitch__child--sp[data-toggle-child=' + toggle_group + ']').hide();
    }
  });

  $('.js--tglSwitch--sp').on('click', function(e){
    if($(e.target).hasClass('js--tglSwitch--sp')){
      $(this).toggleClass('c--tglSwitch--open');
      var toggle_group = $(this).attr('data-toggle-parent');
      $('.js--tglSwitch__child--sp[data-toggle-child=' + toggle_group + ']').stop().slideToggle();
    }
  });
}

/** ------------------------------
 * お知らせポップアップ
 * ---------------------------- */
function firstPopUp() {

  $(function(){
    // 5秒後に強制的に閉じる
    $(".js--message:not(:animated)").fadeIn("slow",function(){
      $(this).delay(5000).fadeOut("slow");
    });
   
    // クリックしたら閉じる
    $(".js--message__close").click(function() {
      $(".js--message").stop().fadeOut("slow");
    });
  });

}

/** ------------------------------
 * selectの選択された文字を常に中央寄せにする
 * ---------------------------- */
function selectCenter() {

  // 初期値設定
  var $label = $('.js--tc__label');
  var $select = $('.js--tc__select');
  // for文で初期値と繰り返しの条件式を指定
  for ( var i=0,l=$label.length; l>i; i++ ) {
    var $option = $($select).eq(i).find('option:selected');
    $('.js--tc__label').text($option.text());
  }

  // 変更時
  $('.js--tc__select').on('change', function(){
    var $this = $(this)
    var $option = $this.find('option:selected');
    $('.js--tc__label').text($option.text());
    // onchange後にフォーカスしてるのが嫌な場合
    $this.blur();
  });
}

/** ------------------------------
 * 追従ボタンエリア
 * ---------------------------- */
function fixedBnr() {
  
  var $f_bnr = $('#js__fixed__bnr');
  var deviceType = $f_bnr.attr('data-device-type');
  var bottom = 0;

  if (deviceType == 'all' || deviceType == 'sp' && window.mnstate.isMobile == true) {
    //スクロール時の動作をページ読み込み時にも
    scrollEvent();
    //スクロール時の動作
    $(window).on("scroll", scrollEvent);
  }

  //スクロール時の動作関数
  function scrollEvent() {

    // 追従バナー切り替えのない検索パネルがあるページの設定
    if($('#js--bnrItem__switch').length == 0 && $('#js--tgl__head--search').length >= 1){
      if($('#js--tgl__head--search').hasClass('p--tgl--open')){
        var $bnrShowBlock = $('#js--bnrShow__block');
        var scrollPos = $(window).scrollTop() + $(window).height();
        var bnrHideHeight = $('.js--tgl__body .p--searchBtnBox').offset().top;
        var $ptBtn = $('#js--pageTop');
        var set_bottom = "20px";

        // 検索パネルが開いている場合は検索パネルが見えなくなるまで追従バナーを表示
        if(scrollPos < bnrHideHeight){

          // 追従エリア切り替え時のTOPへ戻るボタン設定
          var set_bottom = "136px"; // PC時
          if (window.mnstate.isMobile == true) {// SP時
            set_bottom = "122px";
          }

          // 追従エリア設定
          $f_bnr.css({
            'opacity': 1,
            'visibility': 'visible',
            'bottom': bottom
          }).fadeIn("fast");
        }else{
          $f_bnr.fadeOut("fast", function() {
            $(this).css({
              'opacity': 0,
              'visibility': 'hidden'
            });
          });
        }

        // TOPへ戻るボタン設定
        setPageTopForFixedBnr(set_bottom);

        // 検索パネルを閉じた場合は追従バナーも非表示に
        $('#js--tgl__head--search').on('click', function (e) {

          // TOPへ戻るボタン設定
          $ptBtn.css({
            "bottom": "20px",
          });

          // 追従エリア設定
          $f_bnr.fadeOut("fast", function() {
            $(this).css({
              'opacity': 0,
              'visibility': 'hidden'
            });
          });
        });

      }
      return;
    }


    // 追従エリア設定
    // 表示する高さ設定追加 20211220
    var setHeight = '100';
    var $tglHeadSearch = $('#js--tgl__head--search');
    var $bnrItemSwitch = $('#js--bnrItem__switch');
    var $bnrShowBlock = $('#js--bnrShow__block');

    // 追従エリアがある場合
    if($bnrItemSwitch.length){
      var scrollPos = $(window).scrollTop();
      var switchHeight = $bnrShowBlock.offset().top - 35;

      // 検索パネル表示かどうかで表示バナーを切り替え（初期設定）
      if($tglHeadSearch.hasClass('p--tgl--open')){
        // 検索パネル表示時は「この条件で検索」ボタンのある追従が表示
        bnrItemShowSwitch('secondary');

        if($(window).scrollTop() > setHeight){
          // 検索エリアと検索結果エリアが表示されている場合
          if(scrollPos > switchHeight - $(window).height() && scrollPos < switchHeight){

            // 非表示中はクラス削除(TOPへ戻るボタン対応)※追従エリア表示切り替えでも対応
            $f_bnr.removeClass('is-show');

            $f_bnr.fadeOut("fast", function() {
              $(this).css({
                'opacity': 0,
                'visibility': 'hidden'
              });
            });
          }else{
            // 位置によって表示ボタンエリア切り替え
            if(scrollPos > switchHeight){
              bnrItemShowSwitch('primary');
            }else{
              bnrItemShowSwitch('secondary');
            }

            // 表示中はクラス付与(TOPへ戻るボタン対応)※追従エリア表示切り替えでも対応
            $f_bnr.addClass('is-show');

            $f_bnr.css({
              'opacity': 1,
              'visibility': 'visible',
              'bottom': bottom
            }).fadeIn("fast");
          }

        }
      }else{
        bnrShowSwitch(setHeight, $f_bnr, bottom);
        bnrItemShowSwitch('primary');
      }

      // 検索パネル表示クリック切り替えで表示バナーを切り替え
      $('#js--tgl__head--search').on('click', function (e) {
        if($tglHeadSearch.hasClass('p--tgl--open')){
          bnrItemShowSwitch('primary');
        }else{
          bnrItemShowSwitch('secondary');
        }
      });

    // 追従エリアが無い場合
    }else{
      // 追従エリアがない場合（1つしかないので1つ目を表示）
      bnrShowSwitch(setHeight, $f_bnr, bottom);
    }

  }
}

/** ------------------------------
 * 追従エリア表示切り替え
 * ---------------------------- */
function bnrShowSwitch(setHeight, f_bnr, bottom) {
  //ボタンのフェードインフェードアウト
  if ($(window).scrollTop() > setHeight) {

    // 非表示中はクラス追加(TOPへ戻るボタン対応)※検索パネル表示設定関数でも対応
    f_bnr.addClass('is-show');

    // 追従エリア切り替え時のTOPへ戻るボタン設定
    var set_bottom = "136px"; // PC時
    if (window.mnstate.isMobile == true) {// SP時
      set_bottom = "122px";
    }

    // 追従エリア設定
    f_bnr.css({
      'opacity': 1,
      'visibility': 'visible',
      'bottom': bottom
    }).fadeIn("fast");

    // TOPへ戻るボタン設定
    setPageTopForFixedBnr(set_bottom);

  } else {

    // 非表示中はクラス削除(TOPへ戻るボタン対応)※検索パネル表示設定関数でも対応
    f_bnr.removeClass('is-show');

    // 追従エリア設定
    f_bnr.fadeOut("fast", function() {
      $(this).css({
        'opacity': 0,
        'visibility': 'hidden'
      });
    });
  }
}


/** ------------------------------
 * TOPへ戻るボタン設定※ui-common.jsのトップに戻るボタンから色々流用しています
 * ---------------------------- */
 function setPageTopForFixedBnr(set_bottom) {
  var w_width = $(window).innerWidth();
  var s_height = $(document).height();
  var s_position = $(window).height() + $(window).scrollTop();
  var f_height_base = $(".c--subFooter").outerHeight(true) + $(".c--pageTop").innerHeight();
  var $ptBtn = $('#js--pageTop');

  if (w_width >= 768) {
    var f_height = f_height_base + $(".c--privacy__logo").innerHeight();
  }else{
    var f_height = f_height_base - $ptBtn.find('.c--linkArea__btn').height();
  }
  var positionHeight = s_height - s_position; // 画面の高さ - ポジション
  if(w_width >= 767 && fixedBnr){ // 追従バナーPC時だけ値変更
    var positionHeight = s_height - s_position + 38 + 116; // 画面の高さ - ポジション + 追従バナーの高さ分
  }
  if (positionHeight >= f_height) {
    $('#js--pageTop').css({
      'top': 'auto',
      "bottom": set_bottom,
    });
  }
}


/** ------------------------------
 * 追従エリア内のボタン出し分け
 * ---------------------------- */
function bnrItemShowSwitch(showIten) {
  var $bnrItemPrimary = $('#js--bnrItem--primary');
  var $bnrItemSecondary = $('#js--bnrItem--secondary');
  if(showIten == 'primary'){
    $bnrItemPrimary.show();
    $bnrItemSecondary.hide();
  }else{
    $bnrItemPrimary.hide();
    $bnrItemSecondary.show();
  }
}



function fixedBnrClickBtn() {
  var $f_bnr = $('#js__fixed__bnr');
  var $resultIntern = $('.p--result__intern');
  var bottom = 0;

  $('#js--tgl__head--search').on('click', function (e) {
    var resultInternHeight = $resultIntern.offset().top - $resultIntern.css('margin-top').replace('px', ''); //検索結果の上の余白分
    var searchAreaHeight = $('.js--tgl__body--search__area').outerHeight();
    var setHeight = resultInternHeight + searchAreaHeight;

    if(!$('#js--tgl__head--search').hasClass('p--tgl--open')){
      if ($(window).scrollTop() < setHeight) {
        $f_bnr.fadeOut("fast", function() {
          $(this).css({
            'opacity': 0,
            'visibility': 'hidden'
          });
        });
      }
    }else{
      if ($(window).scrollTop() < setHeight) {
        $f_bnr.css({
          'opacity': 1,
          'visibility': 'visible',
          'bottom': bottom
        }).fadeIn("fast");
      }
    }
  });

}


/** ------------------------------
 * モーダルの初期化
 * ※最後に処理させたい
 * ---------------------------- */
function modal() {
  var scrollpos;
  // モーダル開く動作に追加
  var modalSwitchOpen = function () {
    scrollpos = $(window).scrollTop();
    $('body').addClass('fixed').css({'top': -scrollpos});
    $('#nav').addClass('open');
  }
  // モーダル閉じる動作に追加
  var modalSwitchClose = function () {
    $('body').removeClass('fixed').css({'top': 0});

    // datepickerが開いていたらモーダルが閉じるのと同時に閉じる
    if($('#ui-datepicker-div').length){
      $('#ui-datepicker-div').hide();
    }

    // スマホ時に追従バナーがあった場合TOPボタンの位置を再設定
    if (window.mnstate.isMobile == true) {
      $('#js--pageTop').css({
        "bottom": "122px",
      });
    }

    window.scrollTo( 0 , scrollpos );
    $('#nav').removeClass('open');
  }
  
  /** ------------------------------
   * モーダルボタンaタグの場合
   * ---------------------------- */
  $('a').on('click', function (e) {
    var trigger = $(this).attr('data-micromodal-trigger');
    var triggerParent = $(this).parent().attr('data-micromodal-trigger');
    // モーダルの開くボタンを押したかどうか
    if (trigger || triggerParent) {
      e.preventDefault();
    }
  });

  if (!$('body').hasClass('modal--nofocus')) {
    MicroModal.init({
      onShow: modal => modalSwitchOpen(),
      onClose: modal => modalSwitchClose(),
      disableScroll: true,
    });
  } else {
    MicroModal.init({
      onShow: modal => modalSwitchOpen(),
      onClose: modal => modalSwitchClose(),
      disableFocus: true,
      disableScroll: true,
    });
  }
  
  if (!isPsb) {

    if (window.mnstate.isMobile == true) {
      // SP
      $(".js--ps__item--sp").each(function(){
        ps = new PerfectScrollbar(this, {
          suppressScrollX: true
        });
      });
    }else{
      // PC
      $(".js--ps__item--pc").each(function(){
        ps = new PerfectScrollbar(this, {
          suppressScrollX: true
        });
      });
    }
    isPsb = true;
  }

}


/** ------------------------------
 * セルが開くテーブル
 * 要jQuery
 * ---------------------------- */
function accordionTable() {
  const trigger = $('.js--accordionTable__trigger');
  trigger.on('click', function(e){
    e.preventDefault();
    var target = $(this).attr('data-group-target');
    $(this).toggleClass('is-close');
    if($('[data-group-cells=' + target + ']').hasClass('is-close')) {
      $('[data-group-cells=' + target + ']').fadeIn(120).removeClass('is-close');
    } else {
      $('[data-group-cells=' + target + ']').addClass('is-close').fadeOut(450);
    }
  });
}



/** ------------------------------
 * セルが開くテーブル2
 * @param {num} maxHeightValue - セルを畳まない最大の高さ
 * ---------------------------- */
function cellToggle(maxHeightValue = 300) {

  //既定のIDやClass名など
  const attrSetVal = {
    headClass: 'p--comparisonTable__toggle',
    // btnClass: 'p--toggle__btn js--comparisonBtn js--comparisonBtn--withTxt',
    // btnTxtOpen: '表示',
    // btnTxtClose: '閉じる',
    arrowClass: 'p--toggle__btn p--toggle__btn--floating js--comparisonBtn',
  };

  const maxH = maxHeightValue;// セルの高さの初期値
  const targetTable = document.getElementById('js--comparisonTable');//ターゲットになるテーブル
  if(!targetTable){
    return false;
  }
  const targetRow = targetTable.querySelectorAll('tbody tr');//ターゲットのtr。高さのチェック

  /*
   * 読み込み時の処理
   * 高さ計測、要素の追加など
   */
  for (let i = 0; i < targetRow.length; i++) {

    const groupId = 'js--toggleGroup--' + i;//開閉するグループのIDを生成
    const rowH = targetRow[i].offsetHeight;//行の高さ取得

    //行の高さが既定値より低い場合
    if (rowH > maxH) {

      targetRow[i].setAttribute('id', groupId);//行にグループIDをセット
      targetRow[i].setAttribute('class', attrSetVal.headClass);//行に開閉のようのクラスを追加

      const targetRowBody = targetRow[i].querySelectorAll('.p--comparisonTable__body');
      const targetRowHead = targetRow[i].getElementsByTagName('th');

      //行の見出し部分に開閉ボタンを追加
      //targetRowHead[0].innerHTML = targetRowHead[0].innerHTML + '<br>'
      const thBr = document.createElement('br');
      targetRowHead[0].appendChild(thBr);


      const toggleBtn = document.createElement('span');
      // toggleBtn.setAttribute('class', attrSetVal.btnClass);
      toggleBtn.setAttribute('data-target', groupId);
      // toggleBtn.textContent = attrSetVal.btnTxtOpen;
      targetRowHead[0].appendChild(toggleBtn);

      //行の中のコンテンツ部分の処理
      for (let i = 0; i < targetRowBody.length; i++) {

        //セル自体の高さが既定値を超えていたら
        if (targetRowBody[i].offsetHeight > maxH) {

          //ぼかし用のクラスを追加
          targetRowBody[i].classList.add('p--comparisonTable__body--fold');

          //高さ制限用のインナー追加と矢印ボタン追加
          //targetRowBody[i].innerHTML = '<div class="p--comparisonTable__inner" style="height:' + maxH + 'px">' + targetRowBody[i].innerHTML + '</div>';
          //innerHTMLの対処のため上記から変更　20200807
          const innerElems = targetRowBody[i].innerHTML;

          while (targetRowBody[i].firstChild) {
            targetRowBody[i].removeChild(targetRowBody[i].firstChild);
          }

          const newElem = document.createElement('div');
          newElem.setAttribute('class', 'p--comparisonTable__inner');
          newElem.setAttribute('style', 'height:' + maxH + 'px');
          newElem.insertAdjacentHTML('afterBegin', innerElems);

          targetRowBody[i].appendChild(newElem);

          //矢印
          const toggleArrow = document.createElement('span');
          toggleArrow.setAttribute('class', attrSetVal.arrowClass);
          toggleArrow.setAttribute('data-target', groupId);
          targetRowBody[i].parentNode.appendChild(toggleArrow);
        }
      }
    }
  }


  /*
  * セルのトグル処理
  */
  const btn = targetTable.querySelectorAll('.js--comparisonBtn');//開閉のボタン
  for(let i = 0; i < btn.length; i++) {
    //ボタンにイベントを登録
    btn[i].addEventListener('click', function() {
      const id = this.getAttribute('data-target');
      const groupId = document.getElementById(id);
      const cellbody = groupId.querySelectorAll('.p--comparisonTable__body');
      const rowBtns = groupId.querySelectorAll('.js--comparisonBtn');

      //セル
      for (let i = 0; i < cellbody.length; i++) {
        if (cellbody[i].classList.contains('is--open')) {
          cellbody[i].classList.remove('is--open');
        } else {
          cellbody[i].classList.add('is--open');
        }
      }

      //ボタン
      for (let i = 0; i < rowBtns.length; i++) {
        if (rowBtns[i].classList.contains('is--active')) {
          rowBtns[i].classList.remove('is--active')
          // if (rowBtns[i].classList.contains('js--comparisonBtn--withTxt')) {
          //   rowBtns[i].textContent = attrSetVal.btnTxtOpen;
          // }
        } else {
          rowBtns[i].classList.add('is--active')
          // if (rowBtns[i].classList.contains('js--comparisonBtn--withTxt')) {
          //   rowBtns[i].textContent = attrSetVal.btnTxtClose;
          // }
        }
      }

    });
  }

}



/** ------------------------------
 * 上下に切り替わりタブがあるエリア
 * 要jQuery
 * ---------------------------- */
function tabChange() {

  // 初期値：どのエリアを表示させるか
  $('.js--tabHead').each(function(tableNum){
    var index = $(this).children('.p--tabSelect').index();
    $('.js--tabBody').eq(tableNum).children().hide();
    $('.js--tabBody').eq(tableNum).children().eq(index).show();
  });

  // 上部タブをクリックしたとき
  $('.js--tabHead__item').on('click', function(e,arg) {
    if ( !$(this).is(':has(a)') ) {
      e.preventDefault();

      $(this).siblings().removeClass('p--tabSelect');
      $(this).addClass('p--tabSelect');

      var index = $(this).index();
      var tableNum = $('.js--tabHead').index($(this).closest('.js--tabHead'));

      $('.js--tabBody').eq(tableNum).children().hide();
      $('.js--tabBody').eq(tableNum).children().eq(index).show();

    }
  });

  // 下部タブ(アンカーリンク)をクリックしたとき
  $('.inpage_anchor_link').on('click', function(event) {
    event.preventDefault();
    var inpage_anchor = $(this).attr('href');
    var $target_elem = $(inpage_anchor);

    if($target_elem.hasClass("js--tabHead__item")) {
      $target_elem.trigger('click');
      var position = $(inpage_anchor).offset().top;
      var headerHeight = $('header').outerHeight();
      var adjustHeight = position - headerHeight;
      $('html,body').animate({scrollTop:adjustHeight});
    }

  });
}


/** ------------------------------
 * エラー領域に入力を始めたら文字を灰色から黒色にする
 * ---------------------------- */
function inputErrorEdit() {
  let inputs = document.querySelectorAll('.js--input--error');
  for (var i = 0, len = inputs.length; i < len; ++i) {
    inputs[i].addEventListener('input', function() {
      this.style.color = '#333';
    });
  }
}



/** ------------------------------
 * テキストエリアの自動伸縮
 * ---------------------------- */
function stretchTextarea() {
  const sta = document.querySelectorAll('.stretchTextarea')
  const st = document.querySelector('.stretchTextarea')
  autosize(sta);
  if (st != null) {
      st.addEventListener('autosize:resized', function(){});
  }
}



/** ------------------------------
 * ページ内リンク
 * ---------------------------- */
function pageLink() {
  $('a[href^="#"]').on('click', function () {
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}



/** ------------------------------
 * 並び替え矢印の向きをクリックで入れ替え
 * ---------------------------- */
function sortArrowTgl() {

  const sortBtn = document.querySelectorAll('.js--sortTgl');

  for(let i = 0; i < sortBtn.length; i++) {
    sortBtn[i].addEventListener('click', function(){
      if(sortBtn[i].classList.contains('p--sortTgl--down') || sortBtn[i].classList.contains('p--sortTgl--up')){
        sortBtn[i].classList.toggle('p--sortTgl--down');
        sortBtn[i].classList.toggle('p--sortTgl--up');
      }
    });
  }
}




/** ------------------------------
 * セレクトボックスの色
 * ---------------------------- */
function selectErrorTxtColor() {
  // 初期設定
  $('.js-selectedColor').each(function() {
    if(!$(this).hasClass('change')){
      $(this).addClass('no__select');
    }
  });

  // 変更
  $('.js-selectedColor').change(function() {
    if($(this).children('option:selected').attr('data-place-holder') == "true"){
      $(this).addClass('no__select');
      $(this).removeClass('change');
    } else {
      $(this).removeClass('no__select');
      $(this).addClass('change');
    }
  });
}



/** ------------------------------
 * お気に入りボタン切り替え
 * ---------------------------- */
function favChangeAnime() {

  // 文字切り替えアニメーション
  $('.js--favAnime--txt').each(function(index) {
    if ($('.js--favAnime--txt').eq(index).attr('data-mode') == '1') {
      $('.js--favAnime--txt').eq(index).find('.p--favAnime__img--off').hide();
    } else {
      $('.js--favAnime--txt').eq(index).find('.p--favAnime__img--on').hide();
    }
  });

  $('.js--favAnime--txt, .js--favAnime--img').on('click', function(e) {
    e.preventDefault();

    // クラスが無い場合は返す
    if(!$(this).hasClass('check__flg')){
      return;
    }
    // お気に入り解除無しボタン

    if($(this).parent().hasClass('js--favAnime--noCancel') && ($(this).attr('data-mode') == '1' || $(this).hasClass('on'))){
      return;
    }

    if($(this).hasClass('js--favAnime--txt')){

      var animeTxt = $(this);

      if($(this).attr('data-connect')) {
        var connect_num = $(this).attr('data-connect');
        var animeTxt = $('.js--favAnime--txt[data-connect=' + connect_num + ']');
        var animeImg = $('.js--favAnime--img[data-connect=' + connect_num + ']');

        if(animeImg.length > 0){
          var state = $('.js--favAnime--img[data-connect=' + connect_num + ']').hasClass('on');
          var move = $('.js--favAnime--img[data-connect=' + connect_num + ']').hasClass('move');
          favChangeAnimeImg(animeImg,state,move);
        }
      }

      favChangeAnimeTxt(animeTxt);

    }else{

      var animeImg = $(this);
      var state = $(this).hasClass('on');
      var move = $(this).hasClass('move');

      if($(this).attr('data-connect')) {
        var connect_num = $(this).attr('data-connect');
        var animeTxt = $('.js--favAnime--txt[data-connect=' + connect_num + ']');
        var animeImg = $('.js--favAnime--img[data-connect=' + connect_num + ']');

        if(animeTxt.length > 0){
          favChangeAnimeTxt(animeTxt);
        }
      }
      favChangeAnimeImg(animeImg,state,move);
    }
  });
}

function favChangeAnimeTxt(item) {
  if (item.attr('data-mode') == '1') {
    item.attr('data-mode', '0');
    item.find('.p--favAnime__img--on').css('z-index', '1');
    item.find('.p--favAnime__img--off').css('z-index', '1000'); 
    item.find('.p--favAnime__img--off').stop().slideDown('slow', function() {
      if(item.attr('data-mode') == '0'){
        item.find('.p--favAnime__img--on').hide();
      }
    });
  } else {
    item.attr('data-mode', '1');
    item.find('.p--favAnime__img--off').css('z-index', '1');
    item.find('.p--favAnime__img--on').css('z-index', '1000');
    item.find('.p--favAnime__img--on').stop().slideDown('slow', function() {
      if(item.attr('data-mode') == '1'){
        item.find('.p--favAnime__img--off').hide();
      }
    });
  }
}

function favChangeAnimeImg(item,state,move) {
  // onの場合
  if(state){
    item.removeClass('on');
    item.addClass('off');
  // offの場合
  }else{
    item.removeClass('off');
    item.addClass('on');
  }

  // 動きをつけるかどうか
  if(move){
    item.removeClass('move');
  }else{
    item.addClass('move');
  }
  // 1秒後に動きのクラスを外す
  setTimeout(function(){
    item.removeClass('move');
  }, 1000);
}

/** ------------------------------
 * input fileで選択した画像をプレビュー
 * ---------------------------- */
function inputPreview() {
  $(".js--selFile").on('change', function(){
    // 削除ボタンの有無でview_boxの中身が変わる
    if($(this).parents('.js--selFile__wrap').length > 0){
      var view_box = $(this).parents('.js--selFile__wrap').find('.js--selFile__box');
    }else{
      var view_box = $(this).parent().siblings('.js--selFile__box');
    }

    var fileprop = $(this).prop('files')[0],
        filereader = new FileReader(),
        find_img = view_box.find('.p--preview__img');

    // プレビュー画像がある場合は一旦削除
    if(find_img.length){
      find_img.remove();
    }

    // プレビュー画像用HTMLの追加
    var img = '<p class="p--preview__img"><img alt="" class="js--selFile__preview" data-rorate="0"></p>';

    // 90度回転ボタンの表示
    view_box.prepend(img);
    view_box.removeClass('p--state__hide');
    view_box.addClass('p--state__show')

    // 選択ファイル名の変更※削除ボタンの有無で要素位置が違うので分岐
    if($(this).parents('.js--selFile__wrap').length > 0){
      $(this).parents('.js--selFile__wrap').addClass('is--upload');
      $(this).parents('.js--selFile__wrap').find('.js--selFile__name').text(fileprop.name);
    }else{
      $(this).parents('label').siblings('.c--selFile__name').text(fileprop.name);
    }

    filereader.onload = function(e) {
      var i = new Image();
      i.src = e.target.result;
      view_box.find('img').attr('src', i.src);
    }
    filereader.readAsDataURL(fileprop);
  });
}

/** ------------------------------
 * 90度回転
 * ---------------------------- */
function rorateImg() {

  // 角度
  var obj = {
    "rorate0": 0,
    "rorate1": 90,
    "rorate2": 180,
    "rorate3": 270,
  };
  // 初期値
  var rorateBtn = document.querySelectorAll('.js--rorate__btn');
  var fileBox = document.querySelectorAll('.js--selFile__box');

  for(let i = 0; i < rorateBtn.length; i++) {

    // クリック時に画像の回転とdata属性の値を変更する
    rorateBtn[i].addEventListener('click', function(e) {
      e.preventDefault();

      var rorateNum = fileBox[i].getElementsByClassName('js--selFile__preview')[0].dataset.rorate;
      var rorateName = 'rorate' + rorateNum;
      var angle = obj[rorateName] + 90;
      var tmp = "translate3d(-50%, -50%, 0) rotate(" + angle + "deg)";

      this.previousElementSibling.children[0].style.transforWebkitm = tmp;
      this.previousElementSibling.children[0].style.transform = tmp;

      if(rorateNum == 0 || rorateNum == 2){
        this.previousElementSibling.children[0].classList.add('vertical');
      }else{
        this.previousElementSibling.children[0].classList.remove('vertical');
      }

      // データ属性の値設定
      var setRorate = 0;
      if(rorateNum <= 2){
        setRorate = Number(rorateNum) + 1;
      }

      fileBox[i].getElementsByClassName('js--selFile__preview')[0].dataset.rorate = setRorate;

      angle+=90;

    });
  }

}



/** ------------------------------
 * 一時保存ボタン
 * ---------------------------- */
function tempSaveBtn() {
  $(function(){
    $('.js--tempSave').on('click', function(e){
      e.preventDefault();
      // クラスが無い場合は返す
      if(!$(this).hasClass('check__flg')){
        return;
      }
      $(".p--tempSave__popup").fadeIn("fast");
      $(".p--tempSave__popup").delay(2000).fadeOut("slow");
    });
  });
}


/** ------------------------------
 * 枚数分岐スライダー
 * ---------------------------- */
function numberBranchSlider() {

  var sliderItem = document.querySelectorAll('.p--simpleSlider');

  if(sliderItem.length > 0){
    for (var i = 1, item = sliderItem.length; i <= item; ++i) {
      var setNum = ('00' + i).slice(0 - 2);
      /** ------------------------------
       * swiper
       * ---------------------------- */
      // 画像枚数による分岐 --------------------
      // 画像が指定枚数の場合スライダーボタンを非表示にする
      var sliderItem = document.getElementsByClassName('swiper-slide' + setNum);

      // スライダーの設定
      var sliderSetNum = '4'; //PC時
      if (window.mnstate.isMobile == true) { //SP時
        sliderSetNum = '3';
      }

      if(sliderItem.length <= sliderSetNum){
        var btn = document.getElementsByClassName('p--simpleSlider__btns' + setNum);
        btn[0].style.display = "none";
        var slider = document.getElementsByClassName('p--simpleSlider' + setNum);
        slider[0].classList.add('p--no__slider');
      }else{
        // swiper --------------------
        var swiper = new Swiper('.swiper-container' + setNum, {
          navigation: {
            nextEl: '.swiper-button-next' + setNum,
            prevEl: '.swiper-button-prev' + setNum,
          },
          slidesPerView: 3,
          allowTouchMove: true,
          spaceBetween: 5,
          loop: true,
          breakpoints: {
            767: {
              slidesPerView: 4,
              spaceBetween: 20
            }
          }
        });
      }
    }
  }

};

// /** ------------------------------
//  * 枚数分岐スライダー内モーダル
//  * ---------------------------- */
// function numberBranchSliderInModal() {
//   var trigger = document.querySelectorAll('.c--modal__trigger');
//   if(trigger.length > 0){
//     for (var i = 0, trg = trigger.length; i < trg; ++i) {
//       trigger[i].addEventListener('click', function() {

//         // 表示モーダルリストの番号取得
//         var selectShowModalNum = this.getAttribute('data-show-modal');
//         var setNum = ('00' + selectShowModalNum).slice(0 - 2);
//         var target_elem = document.getElementById('js--modal__slider' + setNum);

//         //追加の背景レイヤーを生成
//         var backgroundLayer = document.createElement('div');
//         backgroundLayer.setAttribute('id', 'modal__screen');
//         var beforeelem = document.getElementById('p--simpleSlider__modal');
//         document.body.insertBefore(backgroundLayer, beforeelem);

//         //Close用のボタンを生成
//         var closeBtn = document.createElement('div');
//         closeBtn.setAttribute('id', 'modal__close');
//         target_elem.appendChild(closeBtn);

//         //モーダルを表示
//         var selectModal = '#js--simpleSlider__modal' + setNum;
//         target_elem.closest(selectModal).classList.add('is--visible');

//         var item = document.querySelectorAll(selectModal + ' .modal-slide');

//         if(item.length <= 1){
//           var btn = document.querySelectorAll(selectModal + ' .c--modalSlider__btns');
//           btn[0].style.display = "none";
//         }else{
//           var target_num = this.getAttribute('data-modal-id') - 1;
//           var mySwiper = new Swiper(selectModal + ' .modal_slider-container', {
//             navigation: {
//               nextEl: '.modal_slider_btn-next',
//               prevEl: '.modal_slider_btn-prev',
//             },
//             initialSlide: target_num,
//             loop: true,
//           });
//         }

//         //モーダルを閉じる
//         backgroundLayer.addEventListener('click', modalClose);
//         closeBtn.addEventListener('click', modalClose);

//         function modalClose() {
//           backgroundLayer.remove();
//           closeBtn.remove();
//           target_elem.closest(selectModal).classList.remove('is--visible');
//           if(item.length > 1){
//             mySwiper.destroy(); //一度Swiperの設定を削除(しないと２回目以降挙動がおかしくなる)
//           }
//         }

//       });
//     }
//   }
// };





/** ============================================================
 * AAA-001.html
 * ========================================================== */
/** ------------------------------
 * 「スコアをクリアする」クリックでラジオボタンをクリア
 * ---------------------------- */
function radioAllClear() {
  $('.js--radioClear__btn').on('click', function(e) {
    e.preventDefault();
    var index = $(this).index();
    var itemArray = $('.js--radioClear').eq(index).find('input');

    for (let i = 0; i < itemArray.length; i++) {
      $(itemArray[i]).prop('checked', false);
    }
  });
}



/** ============================================================
 * search-result.html
 * ========================================================== */
/** ------------------------------
 * 指定された数を越えていた場合文字を丸める
 * ---------------------------- */
function textStrimwidth() {
  let items = document.querySelectorAll('.js--strimwidth');

  for (let i = 0; i < items.length; i++) {
    let str = items[i].textContent;
    let pid = items[i].getAttribute('data-set_num');

    if(str.length > pid){
      items[i].textContent = str.substring(0, pid)+'...';
    }

  }
}